import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { NFTRealityBanner } from 'src/modules/nft-reality-banner';

const SContainer = styled(Container)`
    padding-top: 12.5rem;
    padding-left: 0;
    padding-right: 0;
`;

export const NFTRealitySection = () => {
    return (
        <SContainer id="nft-reality">
            <NFTRealityBanner
                h1Part1="nft-reality-banner.h1Part1"
                h1Part2="nft-reality-banner.h1Part2"
                description="nft-reality-banner.description"
            />
        </SContainer>
    );
};
