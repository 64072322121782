import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Project } from 'views/index/our-latest-projects/components/Project';
import { useProjectsData } from 'views/index/our-latest-projects/constants/projectsData';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';

const SSectionTitle = styled(HeaderSecond)`
    font-size: 1.375rem;
    width: fit-content;
    margin: 0 auto 2.25rem;
    padding: 0.2rem;
`;

const SProjectsWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 2rem;
    ${CONSTANTS.MEDIA.max1024`
        flex-direction: column;
    `}
`;

export const OurLatestProjectsSection = () => {
    const projects = useProjectsData();
    return (
        <Container id="latest-projects">
            <SSectionTitle>
                <FormattedMessage id="main-page.our-latest-projects-section.header" />
            </SSectionTitle>
            <SProjectsWrapper>
                {projects.map((item) => (
                    <Project {...item} key={item.id} />
                ))}
            </SProjectsWrapper>
        </Container>
    );
};
